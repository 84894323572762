exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bos-datadelete-js": () => import("./../../../src/pages/bos-datadelete.js" /* webpackChunkName: "component---src-pages-bos-datadelete-js" */),
  "component---src-pages-bos-privacy-js": () => import("./../../../src/pages/bos-privacy.js" /* webpackChunkName: "component---src-pages-bos-privacy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outlet-js": () => import("./../../../src/pages/outlet.js" /* webpackChunkName: "component---src-pages-outlet-js" */),
  "component---src-pages-penjual-js": () => import("./../../../src/pages/penjual.js" /* webpackChunkName: "component---src-pages-penjual-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sayur-js": () => import("./../../../src/pages/sayur.js" /* webpackChunkName: "component---src-pages-sayur-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

